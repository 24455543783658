//#region react
import React, { useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';


import { useTranslation } from "core/hooks/useTranslation";
//#endregion

//#region actions
import {
    getPlayerReport,
    setPlayerReportSorting,
    setPlayerReportFilters,
    getPlayerReportTotals
} from 'store/actions/portal/reports/performance/playerReport.action';
//#endregion

//#region components
import TabTableDashboardLayout from "components/layouts/tab/table";
import Table from "components/common/table";
import Filters from "./filters.component";
import TotalsComponent from './totals';
//#endregion

//#region utils
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
import { isMobile } from 'utils/common';
//#endregion

//#region constants
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'core/constants/permission';
import ApiUrls from 'constants/api.constants';
import { PLAYER_REPORT } from 'constants/pageName.constants';
import { USER_ROLE } from 'constants/user.constants';
import { SEARCH_TYPE } from 'components/common/search/constants';
import { getTableColumns } from './columns';
//#endregion

//#region hooks
import useFormat from 'hooks/useFormat';
import useIncludedColumns from 'hooks/useIncludedColumns';
import useCurrencyConversion from 'hooks/useCurrencyConvertion';
import useAccess from "core/hooks/useAccess";
import usePermissions from 'core/hooks/usePermission';
//#endRegion

//#region constants
import { ADDITIONAL_ACCESS_TYPE } from 'core/constants/additionalAccess';
import ROUTES from 'core/constants/routes/routes';
//#endRegion

//#region types
import playerReportType from 'types/reports/playerReport.type';
import reportTotalsType from 'types/reports/totals.type';
import sortingType from 'types/common/sorting.type';
//#endregion

import { globalProjectIdSelector, useAuthSelector, userRoleSelector } from 'core/stores/authStore';
import { currencySelector, useGlobalSelector } from 'core/stores/globalStore';

/** Player Report Component */
const PlayerReportComponent = ({
    report,
    sorting,
    filters,
    total,
    isLoading,
    getPlayerReport,
    setPlayerReportSorting,
    setPlayerReportFilters,
    getPlayerReportTotals,
    totals
}) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const permissionUtils = usePermissions();

    const globalProjectId = useAuthSelector(globalProjectIdSelector);
    const role = useAuthSelector(userRoleSelector);
    const currencyCode = useGlobalSelector(currencySelector);

    const { formatAmount, formatCurrencyWithSymbol, formatNumber } = useFormat();
    const { convertCurrency } = useCurrencyConversion();

    const { hasAccess } = useAccess()

    const isAffiliate = role === USER_ROLE.AFFILIATE;

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: PLAYER_REPORT });

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasDataExportPermission = permissionUtils.has( PERMISSION_RESOURCE.REPORT_PLAYER_PERFORMANCE, PERMISSION_ACTION.EXPORT )
    const hasTotalsViewPermission = permissionUtils.has( PERMISSION_RESOURCE.REPORT_PLAYER_PERFORMANCE_TOTALS, PERMISSION_ACTION.VIEW )
    const hasAffiliateGeneralInfoPermission = permissionUtils.has( PERMISSION_RESOURCE.AFFILIATE_GENERALINFO, PERMISSION_ACTION.VIEW );

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = value => {
        setPlayerReportFilters({
            ...filters,
            externalId: value,
        })
    }

    const handleDateSearchChange = value => {
        setPlayerReportFilters({
            ...filters,
            from: value[0],
            to: value[1]
        })
    }

    const handleAffiliateClick = useCallback(record => {
        navigate({
            pathname: `${ROUTES.AFFILIATES_EDIT}/${record.affiliateUserName}`,
            search: `name=${record.affiliateUserName}&longId=${record.affiliateId}&fromUsername=true`
        })
    }, [navigate])

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    // columnsThatCanBeIncluded and columnsForExport are only needed in the desktop version.
    const {
        mainTableColumns,
        columnsForExport,
        columnsThatCanBeIncluded
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            includedColumns,
            constructForExport: true,
            constructForInclude: true,
            additionalProps: {
                formatAmount,
                formatCurrencyWithSymbol,
                currencyCode,
                isAffiliate,
                onAffiliateClick: handleAffiliateClick,
                isAffiliateUserNameClickable: hasAffiliateGeneralInfoPermission,
                hasAccess,
                t,
                globalProjectId
            },
            t
        })
    }, [includedColumns, currencyCode, hasAccess, t, hasAffiliateGeneralInfoPermission, isAffiliate, globalProjectId]);

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        filters: <Filters />,
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        search: {
            type: SEARCH_TYPE.INPUT,
            onSearch: handleSearchChange,
            loadFn: getPlayerReport,
            placeholder: t("backoffice.reports.playerExternalId"),
        },
        date: {
            onSearch: handleDateSearchChange,
            placeholder: t('backoffice.reports.period'),
            showTime: true,
            allowClear: false,
            enabledMountsCount: 12,
            loadFn: getPlayerReport,
            value: [filters.from, filters.to]
        },
    }

    if (hasDataExportPermission) {
        headerPartsData.export = {
            tableName: t("backoffice.reports.perPlayer"),
            columns: columnsForExport,
            url: ApiUrls.EXPORT_PLAYER_PERFORMANCE_REPORT,
            filters: filters
        }
    }

    //#endregion

    /** Convert currencies */
    const reportData = useMemo(() => {
        const result = report.map(item => {
            const obj = { ...item };
            obj.adjustmentsAmount = convertCurrency(item.adjustmentsAmount, item.currencyCode);
            obj.casinoBetAmount = convertCurrency(item.casinoBetAmount, item.currencyCode);
            obj.casinoBonusWonAmount = convertCurrency(item.casinoBonusWonAmount, item.currencyCode);
            obj.casinoFeeAmount = convertCurrency(item.casinoFeeAmount, item.currencyCode);
            obj.casinoGGR = convertCurrency(item.casinoGGR, item.currencyCode);
            obj.casinoNGR = convertCurrency(item.casinoNGR, item.currencyCode);
            obj.casinoWonAmount = convertCurrency(item.casinoWonAmount, item.currencyCode);
            obj.cpaAmount = convertCurrency(item.cpaAmount, item.currencyCode);
            obj.depositAmount = convertCurrency(item.depositAmount, item.currencyCode);
            obj.ftdAmount = convertCurrency(item.ftdAmount, item.currencyCode);
            obj.netDepositAmount = convertCurrency(item.netDepositAmount, item.currencyCode);
            obj.ngr = convertCurrency(item.ngr, item.currencyCode);
            obj.sportRevShareAmount = convertCurrency(item.sportRevShareAmount, item.currencyCode);
            obj.casinoRevShareAmount = convertCurrency(item.casinoRevShareAmount, item.currencyCode);
            obj.totalRevShareAmount = convertCurrency(item.totalRevShareAmount, item.currencyCode);
            obj.totalCommissionAmount = convertCurrency(item.totalCommissionAmount, item.currencyCode);
            obj.sportBetAmount = convertCurrency(item.sportBetAmount, item.currencyCode);
            obj.sportBonusWonAmount = convertCurrency(item.sportBonusWonAmount, item.currencyCode);
            obj.sportFeeAmount = convertCurrency(item.sportFeeAmount, item.currencyCode);
            obj.sportGGR = convertCurrency(item.sportGGR, item.currencyCode);
            obj.sportNGR = convertCurrency(item.sportNGR, item.currencyCode);
            obj.sportWonAmount = convertCurrency(item.sportWonAmount, item.currencyCode);
            obj.depositFeeAmount = convertCurrency(item.depositFeeAmount, item.currencyCode);
            obj.withdrawAmount = convertCurrency(item.withdrawAmount, item.currencyCode);

            return obj;
        })
        return result;
    }, [report, convertCurrency]);

    /** Load Totals */
    useEffect(() => {
        if (hasTotalsViewPermission) {
            getPlayerReportTotals();
        }
    }, [globalProjectId, filters])

    /** Table Totals */
    const reportTotalsData = useMemo(() => {
        const curs = Object.keys(totals);
        const result = {};

        if (curs.length > 0) {
            curs.forEach(cur => {
                const item = totals[cur];
                if(hasAccess(ADDITIONAL_ACCESS_TYPE.CASINO_BET_COUNT)){
                    result.casinoBetCount = (result.casinoBetCount ?? 0) + item.casinoBetCount;
                }
                if(hasAccess(ADDITIONAL_ACCESS_TYPE.SPORT_BET_COUNT)){
                    result.sportBetCount = (result.sportBetCount ?? 0) + item.sportBetCount;
                }

                if(hasAccess(ADDITIONAL_ACCESS_TYPE.DEPOSIT_COUNT)){
                    result.depositCount = (result.depositCount ?? 0) + item.depositCount;
                }
                if(hasAccess(ADDITIONAL_ACCESS_TYPE.WITHDRAW_COUNT)){
                    result.withdrawCount = (result.withdrawCount ?? 0) + item.withdrawCount;
                }

                if(hasAccess(ADDITIONAL_ACCESS_TYPE.CASINO_BET_AMOUNT)){
                    result.casinoBetAmount = (result.casinoBetAmount ?? 0) + convertCurrency(item.casinoBetAmount, cur);
                }
                if(hasAccess(ADDITIONAL_ACCESS_TYPE.SPORT_BET_AMOUNT)){
                    result.sportBetAmount = (result.sportBetAmount ?? 0) + convertCurrency(item.sportBetAmount, cur);
                }


                if(hasAccess(ADDITIONAL_ACCESS_TYPE.ADJUSTMENTS)){
                    result.adjustmentsAmount = (result.adjustmentsAmount ?? 0) + convertCurrency(item.adjustmentsAmount, cur);
                }

                if(hasAccess(ADDITIONAL_ACCESS_TYPE.CASINO_BONUS_WON_AMOUNT)){
                    result.casinoBonusWonAmount = (result.casinoBonusWonAmount ?? 0) + convertCurrency(item.casinoBonusWonAmount, cur);
                }
                if(hasAccess(ADDITIONAL_ACCESS_TYPE.SPORT_BONUS_WON_AMOUNT)){
                    result.sportBonusWonAmount = (result.sportBonusWonAmount ?? 0) + convertCurrency(item.sportBonusWonAmount, cur);
                }

                if(hasAccess(ADDITIONAL_ACCESS_TYPE.CASINO_GGR)){
                    result.casinoGGR = (result.casinoGGR ?? 0) + convertCurrency(item.casinoGGR, cur);
                }
                if(hasAccess(ADDITIONAL_ACCESS_TYPE.SPORT_GGR)){
                    result.sportGGR = (result.sportGGR ?? 0) + convertCurrency(item.sportGGR, cur);
                }

                if(hasAccess(ADDITIONAL_ACCESS_TYPE.NGR)){
                    result.ngr = (result.ngr ?? 0) + convertCurrency(item.ngr, cur);
                }
                if(hasAccess(ADDITIONAL_ACCESS_TYPE.CASINO_NGR)){
                    result.casinoNGR = (result.casinoNGR ?? 0) + convertCurrency(item.casinoNGR, cur);
                }
                if(hasAccess(ADDITIONAL_ACCESS_TYPE.SPORT_NGR)){
                    result.sportNGR = (result.sportNGR ?? 0) + convertCurrency(item.sportNGR, cur);
                }

                if(hasAccess(ADDITIONAL_ACCESS_TYPE.CASINO_WON_AMOUNT)){
                    result.casinoWonAmount = (result.casinoWonAmount ?? 0) + convertCurrency(item.casinoWonAmount, cur);
                }
                if(hasAccess(ADDITIONAL_ACCESS_TYPE.SPORT_WON_AMOUNT)){
                    result.sportWonAmount = (result.sportWonAmount ?? 0) + convertCurrency(item.sportWonAmount, cur);
                }

                if(hasAccess(ADDITIONAL_ACCESS_TYPE.DEPOSIT_AMOUNT)){
                    result.depositAmount = (result.depositAmount ?? 0) + convertCurrency(item.depositAmount, cur);
                }
                if(hasAccess(ADDITIONAL_ACCESS_TYPE.WITHDRAW_AMOUNT)){
                    result.withdrawAmount = (result.withdrawAmount ?? 0) + convertCurrency(item.withdrawAmount, cur);
                }
                if(hasAccess(ADDITIONAL_ACCESS_TYPE.FTD_AMOUNT)){
                    result.ftdAmount = (result.ftdAmount ?? 0) + convertCurrency(item.ftdAmount, cur);
                }
                if(hasAccess(ADDITIONAL_ACCESS_TYPE.NET_DEPOSIT_AMOUNT)){
                    result.netDepositAmount = (result.netDepositAmount ?? 0) + convertCurrency(item.netDepositAmount, cur);
                }

                if(hasAccess(ADDITIONAL_ACCESS_TYPE.DEPOSIT_FEE)){
                    result.depositFeeAmount = (result.depositFeeAmount ?? 0) + convertCurrency(item.depositFeeAmount, cur);
                }
                if(hasAccess(ADDITIONAL_ACCESS_TYPE.CASINO_ADMIN_FEE)){
                    result.casinoFeeAmount = (result.casinoFeeAmount ?? 0) + convertCurrency(item.casinoFeeAmount, cur);
                }
                if(hasAccess(ADDITIONAL_ACCESS_TYPE.SPORT_ADMIN_FEE)){
                    result.sportFeeAmount = (result.sportFeeAmount ?? 0) + convertCurrency(item.sportFeeAmount, cur);
                }

                result.sportRevShareAmount = (result.sportRevShareAmount ?? 0) + convertCurrency(item.sportRevShareAmount, cur);
                result.casinoRevShareAmount = (result.casinoRevShareAmount ?? 0) + convertCurrency(item.casinoRevShareAmount, cur);
                result.totalRevShareAmount = (result.totalRevShareAmount ?? 0) + convertCurrency(item.totalRevShareAmount, cur);
                result.cpaAmount = (result.cpaAmount ?? 0) + convertCurrency(item.cpaAmount, cur);
                result.totalCommissionAmount = (result.totalCommissionAmount ?? 0) + convertCurrency(item.totalCommissionAmount, cur);
            })

            if(hasAccess(ADDITIONAL_ACCESS_TYPE.CASINO_BET_COUNT)){
                result.casinoBetCount = formatNumber(result.casinoBetCount);
            }
            if(hasAccess(ADDITIONAL_ACCESS_TYPE.SPORT_BET_COUNT)){
                result.sportBetCount = formatNumber(result.sportBetCount);
            }

            if(hasAccess(ADDITIONAL_ACCESS_TYPE.DEPOSIT_COUNT)){
                result.depositCount = formatNumber(result.depositCount);
            }
            if(hasAccess(ADDITIONAL_ACCESS_TYPE.WITHDRAW_COUNT)){
                result.withdrawCount = formatNumber(result.withdrawCount);
            }

            if(hasAccess(ADDITIONAL_ACCESS_TYPE.CASINO_BET_AMOUNT)){
                result.casinoBetAmount = formatAmount(result.casinoBetAmount, currencyCode);
            }
            if(hasAccess(ADDITIONAL_ACCESS_TYPE.SPORT_BET_AMOUNT)){
                result.sportBetAmount = formatAmount(result.sportBetAmount, currencyCode);
            }

            if(hasAccess(ADDITIONAL_ACCESS_TYPE.ADJUSTMENTS)){
                result.adjustmentsAmount = formatAmount(result.adjustmentsAmount, currencyCode);
            }

            if(hasAccess(ADDITIONAL_ACCESS_TYPE.CASINO_BONUS_WON_AMOUNT)){
                result.casinoBonusWonAmount = formatAmount(result.casinoBonusWonAmount, currencyCode);
            }
            if(hasAccess(ADDITIONAL_ACCESS_TYPE.SPORT_BONUS_WON_AMOUNT)){
                result.sportBonusWonAmount = formatAmount(result.sportBonusWonAmount, currencyCode);
            }

            if(hasAccess(ADDITIONAL_ACCESS_TYPE.CASINO_GGR)){
                result.casinoGGR = formatAmount(result.casinoGGR, currencyCode);
            }
            if(hasAccess(ADDITIONAL_ACCESS_TYPE.SPORT_GGR)){
                result.sportGGR = formatAmount(result.sportGGR, currencyCode);
            }

            if(!isMobile()){
                if(hasAccess(ADDITIONAL_ACCESS_TYPE.NGR)){
                    result.ngr = <span className={result.ngr < 0 ? "rt--error-text" : ""}>{formatAmount(result.ngr, currencyCode)}</span>;
                }
                if(hasAccess(ADDITIONAL_ACCESS_TYPE.CASINO_NGR)){
                    result.casinoNGR = <span className={result.casinoNGR < 0 ? "rt--error-text" : ""}>{formatAmount(result.casinoNGR, currencyCode)}</span>;
                }
                if(hasAccess(ADDITIONAL_ACCESS_TYPE.SPORT_NGR)){
                    result.sportNGR = <span className={result.sportNGR < 0 ? "rt--error-text" : ""}>{formatAmount(result.sportNGR, currencyCode)}</span>;
                }
            } else {
                if(hasAccess(ADDITIONAL_ACCESS_TYPE.NGR)){
                    result.ngr = formatAmount(result.ngr, currencyCode);
                }
                if(hasAccess(ADDITIONAL_ACCESS_TYPE.CASINO_NGR)){
                    result.casinoNGR = formatAmount(result.casinoNGR, currencyCode);
                }
                if(hasAccess(ADDITIONAL_ACCESS_TYPE.SPORT_NGR)){
                    result.sportNGR = formatAmount(result.sportNGR, currencyCode);
                }
            }

            if(hasAccess(ADDITIONAL_ACCESS_TYPE.CASINO_WON_AMOUNT)){
                result.casinoWonAmount = formatAmount(result.casinoWonAmount, currencyCode);
            }
            if(hasAccess(ADDITIONAL_ACCESS_TYPE.SPORT_WON_AMOUNT)){
                result.sportWonAmount = formatAmount(result.sportWonAmount, currencyCode);
            }

            if(hasAccess(ADDITIONAL_ACCESS_TYPE.DEPOSIT_AMOUNT)){
                result.depositAmount = formatAmount(result.depositAmount, currencyCode);
            }
            if(hasAccess(ADDITIONAL_ACCESS_TYPE.WITHDRAW_AMOUNT)){
                result.withdrawAmount = formatAmount(result.withdrawAmount, currencyCode);
            }
            if(hasAccess(ADDITIONAL_ACCESS_TYPE.FTD_AMOUNT)){
                result.ftdAmount = formatAmount(result.ftdAmount, currencyCode);
            }
            if(hasAccess(ADDITIONAL_ACCESS_TYPE.NET_DEPOSIT_AMOUNT)){
                if(!isMobile()){
                    result.netDepositAmount = <span className={result.netDepositAmount < 0 ? "rt--error-text" : ""}>{formatAmount(result.netDepositAmount, currencyCode)}</span>;
                } else {
                    result.netDepositAmount = formatAmount(result.netDepositAmount, currencyCode);
                }
            }
            if(hasAccess(ADDITIONAL_ACCESS_TYPE.DEPOSIT_FEE)){
                result.depositFeeAmount = formatAmount(result.depositFeeAmount, currencyCode);
            }
            if(hasAccess(ADDITIONAL_ACCESS_TYPE.CASINO_ADMIN_FEE)){
                result.casinoFeeAmount = <span className={result.casinoFeeAmount < 0 ? "rt--error-text" : ""}>{formatAmount(result.casinoFeeAmount, currencyCode)}</span>;
            }
            if(hasAccess(ADDITIONAL_ACCESS_TYPE.SPORT_ADMIN_FEE)){
                result.sportFeeAmount = <span className={result.sportFeeAmount < 0 ? "rt--error-text" : ""}>{formatAmount(result.sportFeeAmount, currencyCode)}</span>;
            }

            result.sportRevShareAmount = formatAmount(result.sportRevShareAmount, currencyCode);
            result.casinoRevShareAmount = formatAmount(result.casinoRevShareAmount, currencyCode);
            result.totalRevShareAmount = formatAmount(result.totalRevShareAmount, currencyCode);
            result.cpaAmount = formatAmount(result.cpaAmount, currencyCode);
            result.totalCommissionAmount = formatAmount(result.totalCommissionAmount, currencyCode);
        }

        return result;
    }, [totals, convertCurrency])
    
    return (
        <TabTableDashboardLayout
            header={headerPartsData}
            isContentScrollable={isMobile() && hasTotalsViewPermission}
            extraContent={isMobile() && hasTotalsViewPermission ? (
                <TotalsComponent
                    totals={reportTotalsData}
                    currencyCode={currencyCode}
                />
            ) : undefined}
        >
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={reportData}
                loadFn={getPlayerReport}
                sorting={sorting}
                setSortingFn={setPlayerReportSorting}
                filters={filters}
                setFiltersFn={setPlayerReportFilters}
                total={total}
                updateProps={[globalProjectId]}
                enableReload={true}
                totals={hasTotalsViewPermission && Object.keys(reportTotalsData).length > 0 ? {
                    data: reportTotalsData,
                    title: t("backoffice.common.total")
                } : undefined}
            />

        </TabTableDashboardLayout>
    )
}

/** PlayerReportComponent propTypes
    * PropTypes
*/
PlayerReportComponent.propTypes = {
    /** Redux state property, represents the aplayer report */
    report: PropTypes.arrayOf(playerReportType),
    /** Redux state property, player report sorting details */
    sorting: sortingType,
    /** Redux state property, player report filters */
    filters: PropTypes.object,
    /** Redux state property, player report total count */
    total: PropTypes.number,
    /** Redux state property, is true when loading player report */
    isLoading: PropTypes.bool,
    /** Redux action to get player report */
    getPlayerReport: PropTypes.func,
    /** Redux action to set player report sorting details */
    setPlayerReportSorting: PropTypes.func,
    /** Redux action to set player report filters */
    setPlayerReportFilters: PropTypes.func,
    /** Redux state property, player report totals */
    totals: reportTotalsType,
    /** Redux action to get player report totals */
    getPlayerReportTotals: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        getPlayerReport: nextPage => {
            dispatch(getPlayerReport(nextPage))
        },
        setPlayerReportSorting: sorting => {
            dispatch(setPlayerReportSorting(sorting));
        },
        setPlayerReportFilters: (filters, keepPage) => {
            dispatch(setPlayerReportFilters(filters, keepPage));
        },
        getPlayerReportTotals: () => {
            dispatch(getPlayerReportTotals());
        }
    }
)

const mapStateToProps = state => {
    return {
        report: state.performanceReports.playerReport.report,
        total: state.performanceReports.playerReport.total,
        sorting: state.performanceReports.playerReport.sorting,
        filters: state.performanceReports.playerReport.filters,
        totals: state.performanceReports.playerReport.totals,
        isLoading: state.performanceReports.isLoading,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayerReportComponent)