import { AUTHORIZED_LAYOUT_TYPE } from "./constants";

import LoginPageRoute from "newPages/login";
import ForgotPasswordPageRoute from "newPages/forgotPassword";
import SetPasswordPageRoute from "newPages/setPassword";
import ResetPasswordPageRoute from "newPages/resetPassword";
import ActivateAccountPageRoute from "newPages/activateAccount";
import RegisterPageRoute from "newPages/register";

import ProfilePageRoute from "newPages/profile";
import FinancialRoute from "newPages/financial";
import OperationalRoute from "newPages/operational";
import MarketingRoute from "newPages/marketing";
import CompaniesPageRoute from "newPages/companies";
import CompanyEditPageRoute from "newPages/companyEdit";
import ProjectsPageRoute from "newPages/projects";
import ProjectEditPageRoute from "newPages/projectEdit";
import MyAccountPageRoute from "newPages/myAccount";
import AffiliatesPageRoute from "newPages/affiliates";
import AffiliateEditPageRoute from "newPages/affiliateEdit";
import AffiliateGroupsPageRoute from "newPages/affiliateGroups";
import AffiliateGroupEditPageRoute from "newPages/affiliateGroupEdit";
import CalculationApprovalsPageRoute from "newPages/calculationApprovals";
import InvoicesPageRoute from "newPages/invoices";
import BalanceHistoryPageRoute from "newPages/balanceHistory";
import CampaignsPageRoute from "newPages/campaigns";
import CampaignEditPageRoute from "newPages/campaignEdit";
import MediasPageRoute from "newPages/medias";
import MarketingReportsPageRoute from "newPages/marketingReports";
import PerformanceReportsPageRoute from "newPages/performanceReports";
import CommissionCalculationsPageRoute from "newPages/commissionCalculations";
import AggregatedDataPageRoute from "newPages/aggregatedData";
import UsersPageRoute from "newPages/users";
import UserEditPageRoute from "newPages/userEdit";
import SystemNotificationsPageRoute from "newPages/systemNotifications";
import SystemNotificationEditPageRoute from "newPages/systemNotificationEdit";
import NewsLetterPageRoute from "newPages/newsletter";
import AccessManagersPageRoute from "newPages/accessManagers";
import AccessManagerEditPageRoute from "newPages/acceessManagerEdit";
import AffiliateManagersPageRoute from "newPages/affiliateManagers";
import AffiliateManagerEditPageRoute from "newPages/affiliateManagerEdit";
import PermissionGroupsPageRoute from "newPages/permissionGroups";
import PermissionGroupEditPageRoute from "newPages/permissionGroupEdit";
import PermissionsPageRoute from "newPages/permissions";
import PermissionRequestsPageRoute from "newPages/permissionRequests";
import AdditionalAccessPageRoute from "newPages/additionalAccess";
import PostbacksPageRoute from "newPages/postbacks";
import PostbackEditPageRoute from "newPages/postBackEdit";
import PostbackLogsPageRoute from "newPages/postbackLogs";
import BOTranslationsPageRoute from "newPages/boTranslations";
import PromoTranslationsPageRoute from "newPages/promoTranslations";
import UserLogsPageRoute from "newPages/userLogs";
import SystemCurrenciesPageRoute from "newPages/systemCurrencies";
import SystemLanguagesPageRoute from "newPages/systemLanguages";
import SystemPaymentsPageRoute from "newPages/systemPayments";
import SystemPaymentEditPageRoute from "newPages/systemPaymentEdit";
import ErrorsPageRoute from "newPages/errors";
import RequestsPageRoute from "newPages/requests";
import JobsPageRoute from "newPages/jobs";
import MonitoringPageRoute from "newPages/monitoring";
import PlatformTestPageRoute from "newPages/platformTest";
import GenerationsPageRoute from "newPages/generations";
import DBConnectionsPageRoute from "newPages/dbConnections";
import PostDeploymentActionsPageRoute from "newPages/postDeploymentActions";
import PromoCustomizePageRoute from "newPages/promoCustomize";
import NewsletterCustomizePageRoute from "newPages/newsletterCustomize";
import GuideRoute from "newPages/guide";
import PlayersPageRoute from "newPages/players";
import PlayerBtagChangeHistoryPageRoute from "newPages/playerBtagChangeHistory";

export const authorizedRoutes = {
    [AUTHORIZED_LAYOUT_TYPE.PORTAL] : [
        OperationalRoute,
        FinancialRoute,
        MarketingRoute,
        CompaniesPageRoute,
        CompanyEditPageRoute,
        ProjectsPageRoute,
        ProjectEditPageRoute,
        MyAccountPageRoute,
        AffiliatesPageRoute,
        AffiliateEditPageRoute,
        AffiliateGroupsPageRoute,
        AffiliateGroupEditPageRoute,
        PlayersPageRoute,
        PlayerBtagChangeHistoryPageRoute,
        CampaignsPageRoute,
        CampaignEditPageRoute,
        MediasPageRoute,
        CalculationApprovalsPageRoute,
        InvoicesPageRoute,
        BalanceHistoryPageRoute,
        CommissionCalculationsPageRoute,
        MarketingReportsPageRoute,
        PerformanceReportsPageRoute,
        AggregatedDataPageRoute,
        UsersPageRoute,
        UserEditPageRoute,
        AffiliateManagersPageRoute,
        AffiliateManagerEditPageRoute,
        AccessManagersPageRoute,
        AccessManagerEditPageRoute,
        PermissionGroupsPageRoute,
        PermissionGroupEditPageRoute,
        PermissionRequestsPageRoute,
        PostbacksPageRoute,
        PostbackEditPageRoute,
        PostbackLogsPageRoute,
        PermissionsPageRoute,
        AdditionalAccessPageRoute,
        SystemNotificationsPageRoute,
        SystemNotificationEditPageRoute,
        NewsLetterPageRoute,
        UserLogsPageRoute,
        BOTranslationsPageRoute,
        PromoTranslationsPageRoute,
        SystemCurrenciesPageRoute,
        SystemLanguagesPageRoute,
        SystemPaymentsPageRoute,
        SystemPaymentEditPageRoute,
        ErrorsPageRoute,
        RequestsPageRoute,
        JobsPageRoute,
        MonitoringPageRoute,
        PlatformTestPageRoute,
        GenerationsPageRoute,
        PostDeploymentActionsPageRoute,
        DBConnectionsPageRoute,
        ProfilePageRoute,
    ],

    [AUTHORIZED_LAYOUT_TYPE.CUSTOMIZE] : [
        PromoCustomizePageRoute,
        NewsletterCustomizePageRoute,
    ],

    [AUTHORIZED_LAYOUT_TYPE.GUIDE] : [
        GuideRoute
    ],

}

export const anonymousRoutes = [
    LoginPageRoute,
    ForgotPasswordPageRoute,
    SetPasswordPageRoute,
    ResetPasswordPageRoute,
    ActivateAccountPageRoute,
    RegisterPageRoute,
]