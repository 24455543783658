import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types'

import { useTranslation } from "core/hooks/useTranslation";

import { Col, Row } from 'antd';


import TabTableDashboardLayout from 'components/layouts/tab/table';
import Leaderboard from 'components/common/leaderboard';
import Filters from '../../../components/filters';

import { FINANCIAL_ENTITY_TYPE, FINANCIAL_LEADERBOARD_TYPE, TIME_PERIOD } from '../../../constants';
import { LEADERBOARD_DATA_TYPE, LEADERBOARD_TYPE } from 'components/common/leaderboard/constants';

import useFirstRender from 'hooks/useFirstRender';
import useFinancialLeaderboard from '../../../hooks/useFinancialLeaderboard';
import useAccess from 'core/hooks/useAccess';

import { isMobile } from 'utils/common';

import { globalProjectIdSelector, useAuthSelector } from 'core/stores/authStore';
import { currenciesSelector, currencySelector, useGlobalSelector } from 'core/stores/globalStore';
import { ADDITIONAL_ACCESS_TYPE } from 'core/constants/additionalAccess';

const getDefaultFilters = (currencyCode, dataType) => {
    return {
        period: {
            period: TIME_PERIOD.TODAY,
            from: null,
            to: null
        },
        dataType: dataType,
        currencyCode
    }
}

const TopAffiliates = ({
    updateLastUpdatedDate
}) => {
    const { t } = useTranslation();

    const currencyCode = useGlobalSelector(currencySelector);
    const currencies = useGlobalSelector(currenciesSelector);
    const { hasAccess } = useAccess();

    const initialDataType = (
        hasAccess(ADDITIONAL_ACCESS_TYPE.NGR) ? FINANCIAL_ENTITY_TYPE.NGR :
        hasAccess(ADDITIONAL_ACCESS_TYPE.GGR) ? FINANCIAL_ENTITY_TYPE.GGR : 
        hasAccess(ADDITIONAL_ACCESS_TYPE.BET_AMOUNT) ? FINANCIAL_ENTITY_TYPE.TURNOVER : FINANCIAL_ENTITY_TYPE.EARNING_COMMISSIONS_AMOUNT
    )

    const [ filters, setFilters ] = useState(getDefaultFilters(currencyCode, initialDataType));

    const updateFilters = useCallback((newFilters) => {
        setFilters(prevState => {
            return {
                ...prevState,
                ...newFilters
            }
        })
    }, [])

    const globalProjectId = useAuthSelector(globalProjectIdSelector);

    const { leaderBoardData, isLoading } = useFinancialLeaderboard(FINANCIAL_LEADERBOARD_TYPE.AFFILIATE, filters, updateLastUpdatedDate)

    useFirstRender({
        afterFirstRenderCB: () => {
            updateFilters(getDefaultFilters(currencyCode))
        },
        dependencies: [globalProjectId, updateFilters]
    })

    const dataTypeFields = useMemo(() => {
        const result = [];
        if(hasAccess(ADDITIONAL_ACCESS_TYPE.NGR)){
            result.push({ value: FINANCIAL_ENTITY_TYPE.NGR, text: t("backoffice.dashboard.ngr") })
        }

        if(hasAccess(ADDITIONAL_ACCESS_TYPE.GGR)){
            result.push({ value: FINANCIAL_ENTITY_TYPE.GGR, text: t("backoffice.dashboard.ggr") })
        }

        if(hasAccess(ADDITIONAL_ACCESS_TYPE.BET_AMOUNT)){
            result.push({ value: FINANCIAL_ENTITY_TYPE.TURNOVER, text: t("backoffice.dashboard.turnover") })
        }

        result.push({ value: FINANCIAL_ENTITY_TYPE.EARNING_COMMISSIONS_AMOUNT, text: t("backoffice.dashboard.earning") });
        result.push({ value: FINANCIAL_ENTITY_TYPE.CPA_COMMISSIONS_AMOUNT, text: t("backoffice.dashboard.approvedCpa") })

        return result;
    }, [hasAccess, t])

    return (
        <TabTableDashboardLayout
            header={{
                filters: isMobile() ? (
                    <Filters 
                        filters={filters} 
                        updateFilters={updateFilters} 
                        showDataType={true}
                        showCurrency={true}
                        currencies={currencies}
                        dataTypeFields={dataTypeFields}
                    />
                ) : undefined,
                content: !isMobile() ? (
                    <div className="rt--flex rt--align-center rt--justify-end rt--width-full">
                        <Filters 
                            filters={filters} 
                            updateFilters={updateFilters} 
                            showDataType={true}
                            showCurrency={true}
                            currencies={currencies}
                            dataTypeFields={dataTypeFields}
                        />
                    </div>
                ) : undefined
            }}
        >
            <div className='rt--chart-top'>
                <Row gutter={[16, 24]}>
                    <Col span={24}>
                        <Leaderboard
                            leaders={leaderBoardData}
                            isLoading={isLoading}
                            title={t("backoffice.dashboard.topAffiliates")}
                            type={LEADERBOARD_TYPE.FINANCIAL}
                            dataType={LEADERBOARD_DATA_TYPE.AFFILIATE}
                            currencyCode={filters.currencyCode}
                        />
                    </Col>
                </Row>
            </div>
        </TabTableDashboardLayout>
    )

}

TopAffiliates.propTypes = {
    updateLastUpdatedDate: PropTypes.func,
}

export default TopAffiliates;